import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import { dateParser } from '../utils/swr-config';

export interface RosterHeader {
	id: string;
	name: string;
	slug: string;
}

export interface RosterDetails extends RosterHeader {
	description?: string;
	department?: string;
	coordinatorName: string;
	parentCommittee: string;
	emailAddress?: string;
	startDate: Date;
	endDate?: Date;
	directorName: string;
	members: RosterMember[];
}

export interface RosterMember {
	id: string;
	name: string;
	email: string;
	company: string;
	phoneNumber: string;
	startDate: Date;
	endDate?: Date;
}

export function useRosters() {
	const { data: rosters, error, isLoading } = useSWR<RosterHeader[]>('/api/Rosters/GetAll', {
		use: [dateParser('startDate', 'endDate')]
	});

	return {
		rosters,
		error,
		isLoading
	};
}

export function useRoster(rosterSlug: string | undefined) {
	const { state } = useLocation();
	const { rosters, error, isLoading } = useRosters();

	const roster: RosterHeader | undefined = rosters?.find(r => r.slug === rosterSlug) || state?.roster;

	return { roster, error, isLoading };
}

export function useRosterDetails(rosterId: string | undefined) {

	const { data: rosterDetails, error, isLoading } = useSWR<RosterDetails>(rosterId ? `/api/Rosters/GetRosterDetails/${rosterId}` : null, {
		use: [dateParser('startDate', 'endDate')]
	});

	if (rosterDetails) {
		rosterDetails.members = rosterDetails.members.map(member => {
			member.startDate = new Date(member.startDate);
			if (member.endDate) member.endDate = new Date(member.endDate);

			return member;
		})
	}

	return {
		rosterDetails,
		error,
		isLoading
	};
}